
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's182ab2d6c997515f': `将其添加到主屏幕，以享受更全面的体验并便于访问。`,
's1b9047d53d9f9d22': `2) 点击列表上的分享按钮`,
's37a9e8aec5713460': `详细资讯`,
's5c6aad7a7e4a1437': `3) 点击添加到主屏幕`,
's6196153c4b0c1ea0': `安装`,
's922329d6f6213590': `添加到Dock`,
'sa5ef80b4bb9b39f8': `简短资讯`,
'sa7551383d1897fcc': `2) 按下添加到Dock`,
'sba52286c21552a4e': `在您的设备上安装，以享有更全面的体验和便捷的访问。`,
'sc79fd7641eb9a975': `隐藏安装流程`,
'scdaf4bbff76674c8': `将App添加到主屏幕`,
'sdfe23506a3b0cdc8': `1) 在Safari上浏览此网页`,
'se740f75b95a51807': `将其添加到您的Dock，以享受更全面的体验并便于访问。`,
'sfea652f6580ff086': `此网站具备app的功能性。`,
    };
  